import React from 'react';
import NewsDetailContainer from '../components/NewsDetailContainer/NewsDetailContainer';

const NewsDetailView = () => {
	return (
	<div>
		<NewsDetailContainer />
	</div>
	)
};

export default NewsDetailView;
