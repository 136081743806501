import React from 'react';
import LoginForm from '../components/Login/LoginForm';

const Login = () => {
	return (
		<div>
			<LoginForm />
		</div>
	);
};

export default Login;
