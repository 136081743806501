import React from 'react';
import DigestoContainer from '../components/DigestoContainer/DigestoContainer';

const Legislacion = () => {
	return (
		<div>
			<DigestoContainer />
		</div>
	);
};

export default Legislacion;
