import React from 'react';
import AdminDashboard from '../components/AdminDashboard/AdminDashboard';

const Dashboard = () => {
	return (
		<div>
			<AdminDashboard />
		</div>
	);
};

export default Dashboard;
